import request from '@/utils/request'

// !获取合作伙伴
export function Partners(params) {
  return request({
    url: '/Partner/Partners',
    method: 'GET',
    params
  })
}

// !获取合作伙伴的联系方式
export function CompanyInfo(params) {
  return request({
    url: '/Partner/CompanyInfo',
    method: 'GET',
    params
  })
}

// !获取入门学习指导 文章列表
export function GetLearn(params) {
  return request({
    url: '/IntroducLearn/GetLearn',
    method: 'GET',
    params
  })
}


// !获取所有角色
export function GetRoles(params) {
  return request({
    url: '/IntroducLearn/GetRoles',
    method: 'GET',
    params
  })
}