<template>
  <div class="Partner">
    <div v-loading="loading"></div>
    <swiper :banners="banners"></swiper>
    <div class="Partner-index">
      <div class="crumb">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div class="Partner-index-head" :class="clientWidth > 768 ? 'flex' : 'flex4'">
        <div class="Partner-index-head-item flex" v-for="(item, index) in partnerNav" :key="item.id" @click="TabType(item.title)" :class="item.title == params.type ? 'Partner-index-head-item-active' : ''">
          <div class="Partner-index-head-item-l flex0">
            <div>
              <div class="">
                <img v-if="index === 3" src="@/assets/PC/partner/logo_violet.png" alt="">
                <img v-else src="@/assets/PC/partner/logo.png" alt="">
              </div>
              <div class="Partner-index-head-line"></div>
              <div v-html="item.leftTitle"></div>
            </div>
          </div>
          <div class="Partner-index-head-item-r flex0">
            <div>{{item.title.length > 4 ? item.title.slice(0,4) : item.title}} <br/> {{item.title.length > 4 ? item.title.slice(4) : ''}}</div>
          </div>
        </div>
      </div>
      <div class="Partner-content" :class="clientWidth > 768 ? 'flex': ''">
        <div v-if="!typeState" class="Partner-content-left" ref="navState">
          <div class="Partner-search">
            <el-input type="text" v-model="params.content" placeholder="请输入" @keyup.enter.native="SearchFilter">
              <i slot="prefix" class="el-input__icon el-icon-search cur" @click="SearchFilter"></i>
            </el-input>
          </div>
          <div class="fence-nav-item" v-for="(item, index) in navList" :key="item.id">
            <div style="position: relative;">
              <div class="fence-nav-item-title" :class="clientWidth > 768 ? 'flex3': 'flex0'" @click="mobileNavTab(index)">
                <b>{{ item.title }}</b>
                <i class="el-icon-caret-bottom"></i>
              </div>
              <el-collapse-transition>
                <div class="fence-nav-item-menu" v-show="(clientWidth > 768 && !mobileNavState[index]) || (clientWidth < 769 && mobileNavState[index])">
                  <div class="fence-nav-item-menu-item" v-for="itemChild in item.list" :key="itemChild.text" @click="navTab(index, itemChild.text)" :class="NavActive(index, itemChild.text) ? 'nav-active' : ''">
                    {{itemChild.text}}
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
        </div>
        <div class="Partner-content-right">
          <div v-if="typeState" class="Partner-search" :class="clientWidth > 768 ? 'mb15' : 'mb'">
            <el-input type="text" v-model="params.content" placeholder="请输入" @keyup.enter.native="SearchFilter">
              <i slot="prefix" class="el-input__icon el-icon-search cur" @click="SearchFilter"></i>
            </el-input>
          </div>
          <div v-if="dataState">
            <div v-if="resData.data.length" class="Partner-lists" :class="clientWidth > 768 ? 'flex4' : ''">
              <div class="Partner-item" v-for="item in resData.data" :key="item.id" :class="silverTypeState ? 'typeState' : ''">
                <!-- logo -->
                <div v-if="!silverTypeState" class="Partner-item-logo flex2">
                  <img :src="item.logoUrl" alt="" width="100%">
                </div>
                <!-- 标题、联系我们 -->
                <div v-if="!silverTypeState" class="Partner-item-company">{{ item.cN_Name }}</div>
                <div v-else :class="clientWidth > 768 ? 'flex6' : ''">
                  <div v-if="!item.abstract" class="Partner-item-company">{{ item.cN_Name }}</div>
                  <el-popover
                    v-else
                    placement="bottom"
                    :width="clientWidth > 768 ? '700' : '280'"
                    trigger="hover">
                    {{item.abstract}}
                    <div class="Partner-item-company cur" slot="reference">{{ item.cN_Name }}</div>
                  </el-popover>
                  <div v-if="silverTypeState">
                    <div class="Partner-call">
                      <el-popover
                        v-if="token"
                        placement="top"
                        title="联系方式"
                        width="270"
                        trigger="click"
                        v-model="item.visible">
                        <div>
                          <p class="flex4">姓名：{{callDetails.data.contact ? callDetails.data.contact: '-'}}</p>
                          <p class="flex4">邮箱：{{!callDetails.data.email ? '-': ''}}<a v-if="callDetails.data.email" class="link" :href="'mailto:' + callDetails.data.email">{{callDetails.data.email}}</a></p>
                          <p class="flex4">电话：{{callDetails.data.phone ? callDetails.data.phone : '-'}}</p>
                          <p class="flex4">网址：{{!callDetails.data.websiteUrl ? '-': ''}}<a v-if="callDetails.data.websiteUrl" class="link" :href="callDetails.data.websiteUrl" target="_blank"> {{ callDetails.data.websiteUrl }} </a></p>
                        </div>
                        <a slot="reference" href="javascript: void(0)" @click="callUs(item.id)"><i class="el-icon-mobile"></i> 联系我们</a>
                      </el-popover>
                      <a v-else href="javascript: void(0)" @click="callUs(item.id)"><i class="el-icon-mobile"></i> 联系我们</a>
                    </div>
                  </div>
                </div>
                <!-- 英文标题 -->
                <div v-if="!silverTypeState" class="Partner-item-company">{{ item.eN_Name }}</div>
                <!-- 简介 -->
                <div v-if="!silverTypeState" class="flex6">
                  <el-popover v-if="item.abstract.length > 130"
                    placement="bottom"
                    :width="clientWidth > 768 ? '700' : '280'"
                    trigger="hover">
                    {{item.abstract}}
                    <div class="Partner-item-describe hidden6" slot="reference">{{ item.abstract }}</div>
                  </el-popover>
                  <div v-else class="Partner-item-describe hidden6">{{ item.abstract }}</div>
                  <div v-if="item.certifications" class="Partner-item-certificate">
                    <el-tooltip v-if="clientWidth > 768" class="item" effect="dark" content="点击查看大图" placement="bottom">
                      <!-- <img :src="item.certifications" alt="" width="100%"> -->
                      <el-image
                        :src="item.certifications"
                        :z-index="999999"
                        :preview-src-list="[item.certifications]">
                      </el-image>
                    </el-tooltip>
                    <img v-else :src="item.certifications" alt="" width="100%" @click="mobilePreview(item.certifications)">
                  </div>
                </div>
                <!-- !联系我们 -->
                <div v-if="!silverTypeState && !solutionTypeState" class="Partner-footer" :class="clientWidth > 768 ? 'flex1' : ''">
                  <div class="Partner-call">
                    <el-popover
                      v-if="token"
                      placement="top"
                      title="联系方式"
                      width="270"
                      trigger="click"
                      v-model="item.visible">
                      <div>
                        <p class="flex4">姓名：{{callDetails.data.contact ? callDetails.data.contact: '-'}}</p>
                        <p class="flex4">邮箱：{{!callDetails.data.email ? '-': ''}}<a v-if="callDetails.data.email" class="link" :href="'mailto:' + callDetails.data.email">{{callDetails.data.email}}</a></p>
                        <p class="flex4">电话：{{callDetails.data.phone ? callDetails.data.phone : '-'}}</p>
                        <p class="flex4">网址：{{!callDetails.data.websiteUrl ? '-': ''}}<a v-if="callDetails.data.websiteUrl" class="link" :href="callDetails.data.websiteUrl" target="_blank"> {{ callDetails.data.websiteUrl }} </a></p>
                      </div>
                      <a slot="reference" href="javascript: void(0)" @click="callUs(item.id)"><i class="el-icon-mobile"></i> 联系我们</a>
                    </el-popover>
                    <a v-else href="javascript: void(0)" @click="callUs(item.id)"><i class="el-icon-mobile"></i> 联系我们</a>
                  </div>
                </div>
                <div v-if="solutionTypeState" class="Partner-footer" :class="clientWidth > 768 ? 'flex1' : ''">
                  <div class="Partner-call Partner-call-disable">
                    <a href="javascript: void(0)"><i class="el-icon-mobile"></i> 联系我们</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="noData">
              <img src="@/assets/PC/profile/listNone.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import shadeAlert from "@/components/shadeAlert/index.vue";
import { mapState } from 'vuex'
import swiper from "@/components/Swiper.vue";
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { Partners, CompanyInfo } from '@/api/partner.js'
import { GetShareData, GetWxShares } from '@/utils/common'
import { ImagePreview } from 'vant'

export default {
  name: 'Partner',
  data () {
    return {
      // ! 页面loading
      loading: true,
      // !移动端导航展示状态
      mobileNavState: [false],
      // !轮播图
      banners: [],
      // !面包屑
      refer: [
        { name: "首页", href: "/" },
        { name: "合作伙伴" },
      ],
      partnerNav: [
        { id: 100, leftTitle: 'Distributor', title: '分销商' },
        { id: 101, leftTitle: 'Solution <br/> Provider', title: '解决方案供应商' },
        { id: 102, leftTitle: '<span style="font-size: 12px">CERTIFIED</span> <br/> System <br/> Integrator', title: '金牌系统集成商' },
        { id: 103, leftTitle: '<span style="font-size: 12px">REGISTERED</span> <br/> System <br/> Integrator', title: '银牌系统集成商' },
      ],
      navList: [
        {
          id: 10001,
          title: '覆盖 AVEVA 业务范围',
          list: [
            { text: '全部' },
            { text: '全线产品' },
            { text: '运营类产品' },
            { text: '工程设计产品' }
          ]
        }
      ],
      // !筛选参数
      params: {
        // @搜索
        content: '',
        // @ 推荐
        business: '全部',
        // @ 类型
        type: '分销商'
      },
      // !数据是否返回
      dataState: false,
      resData: {
        data: [],
      },
      // !联系我们详情
      callDetails: {
        state: false,
        data: {}
      },
      // !弹窗配置
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: "",
        text: "", // 弹窗内容
        btnType: "", // 按钮形式
        btnList: [],
      },
    }
  },
  mounted() {
    this.banners = [
      {
        imgUrl: require('../../assets/PC/partner/partner2.png'),
        url: '',
        context: ''
      },
      {
        imgUrl: require('../../assets/PC/partner/partner1.png'),
        url: '',
        context: ''
      },
    ]
    this.GetInit()
    document.addEventListener("click", e =>{
      if (document.documentElement.clientWidth < 750) {
        if(this.$refs.navState) {
          if(!this.$refs.navState.contains(e.target)) {
            this.mobileNavState = [false]
          }
        }
      }
    });
    GetShareData(1, "Partner", '');
    const share = {
      title: '合作伙伴 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
  },
  methods: {
    // !是否选中状态
    NavActive(index,childId) {
      return (index == 0 && childId === this.params.business)
    },
    // !供应商筛选
    TabType(type) {
      this.params = {
        type,
        business: '全部',
        content: ''
      }
      this.GetInit()
    },
    // !移动端 筛选
    mobileNavTab(index) {
      if(this.clientWidth <769){
        if(index == 0) {
        this.mobileNavState = [true]
      }
      }
    },
    // ! pc端 筛选
    navTab(index, ChildValue) {
      if(index == 0) {
        this.params.business = ChildValue
        this.GetInit()
      }
    },
    // !获取列表
    GetInit() {
      this.loading = true
      Partners(this.params).then(res => {
        if(res.status === 1) {
          this.dataState = true;
          this.loading = false;
          this.resData = res
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !搜索框筛选
    SearchFilter() {
      this.GetInit()
    },
    // !联系我们
    callUs(id) {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来联系我们吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: '已有剑盟账号，前往登录',
              link: '/Login?redirectUrl=' + this.$route.fullPath,
            },
            {
              text: '没有账号，立即注册',
              link: '/Register?redirectUrl=' + this.$route.fullPath,
            },
          ],
        };
      } else {
        GetShareData(2, "Partner", id);
        CompanyInfo({id}).then(res =>{
          if (res.status === 1) {
            this.callDetails.data = res.data
          } else {
            this.$toast(res.message)
          }
        })
      }
    },
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
    // !移动端预览图片
    mobilePreview(images) {
      ImagePreview([images]);
    }
  },
  computed: {
    ...mapState(['clientWidth', 'token']),
    typeState () {
      return ['金牌系统集成商', '银牌系统集成商', '解决方案供应商'].includes(this.params.type)
    },
    silverTypeState() {
      return ['银牌系统集成商'].includes(this.params.type)
    },
    solutionTypeState() {
      return ['解决方案供应商'].includes(this.params.type)
    }
  },
  components: {
    swiper,
    breadcrumb,
    shadeAlert
  },
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>